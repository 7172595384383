<template>
  <el-card shadow="never">
    <el-row style="margin-bottom: 22px">
      <el-select v-model="queryInfo.chargeLineCode" class="input" placeholder="运作产线" clearable filterable>
        <el-option v-for="item in chargeLineCodeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
      </el-select>
      <el-select v-model="queryInfo.materialSource" class="input" placeholder="备件来源" clearable filterable>
        <el-option v-for="item in materialSourceList" :key="item.value" :label="item.name" :value="item.value"></el-option>
      </el-select>
      <el-select v-model="queryInfo.subClass" class="input" placeholder="备件小类" clearable filterable>
        <el-option v-for="item in subClassList" :key="item.value" :label="item.name" :value="item.value"></el-option>
      </el-select>
      <el-input v-model="queryInfo.lendOrderCode" class="input" placeholder="借出单号" clearable></el-input>
      <el-input v-model="queryInfo.materialCode" class="input" placeholder="备件编码" clearable></el-input>
      <el-switch
          style="margin-right: 10px"
          v-model="queryInfo.disabled"
          active-text="查看已失效">
      </el-switch>
      <el-button type="primary" @click="currentChange(1)" :loading="loading">查询</el-button>
      <el-button type="primary" @click="dialogVisible = true">添加</el-button>
    </el-row>

    <div style="color: orange;padding-bottom:30px;font-size: 12px;line-height: 14px">
      提示:<br/>
      1、检验比例：是借出单下某备件的电性能数量/备件总数量(外观+电性能)x100% ，如果比例为0%则表示免检，100%表示全检。但最多不会超过联想规定的电性能检验数量。<br/>
      2、检验比例若超过了联想设置的比例，以联想的准。<br/>
      3、条件优先级：借出单号>备件编码。<br/>
      4、优先级：条件越全值越大，优先级值越大优先级越高。<br/>
    </div>
    <div style="padding-bottom:10px">
      <span style="color: red;font-size: 16px;padding-right: 10px">该功能当前状态为【{{ exemption ? '使用中' : '已停用' }}】</span>
      <el-button type="primary" size="mini" v-if="exemption !==true" @click="openRule">启用功能</el-button>
      <el-button type="primary" size="mini" v-if="exemption ===true" @click="closeRule">停用功能</el-button>
    </div>
    <el-table :data="ruleList" border style="width: 100%" stripe>
      <el-table-column align="center" prop="chargeLineName" label="运作产线"></el-table-column>
      <el-table-column align="center" prop="materialSourceName" label="备件来源"></el-table-column>
      <el-table-column align="center" prop="subClassName" label="备件小类"></el-table-column>
      <el-table-column align="center" prop="lendOrderCode" label="借出单号"></el-table-column>
      <el-table-column align="center" prop="materialCode" label="备件编码"></el-table-column>
      <el-table-column align="center" prop="testRate" label="检验比例(%)" width="90px"></el-table-column>
      <el-table-column align="center" prop="weigh" label="优先级" width="90px"></el-table-column>
      <el-table-column align="center" prop="createUserName" label="创建人" width="80px"></el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间" width="150px"></el-table-column>
      <el-table-column align="center" prop="updateUserName" label="失效人" width="80px"></el-table-column>
      <el-table-column align="center" prop="updateTime" label="失效时间" width="150px"></el-table-column>
      <el-table-column align="center" label="操作" width="80px">
        <template slot-scope="scope">
          <el-button @click="disableRule(scope.row)" type="danger" size="small" v-if="!scope.row.disabled">失效</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="queryInfo.pageNo"></el-pagination>
    </div>
    <el-dialog title="添加检验比例设置" :visible.sync="dialogVisible" :close-on-click-modal="false" width="700px" :before-close="handleClose">
      <el-form :model="formData" :rules="formDataRules" label-width="200px" ref="addFormData">
        <el-form-item label="运作产线：" prop="chargeLineCode">
          <el-select v-model="formData.chargeLineCode" placeholder="请选择运作产线" clearable filterable style="width: 350px">
            <el-option v-for="item in chargeLineCodeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备件来源：" prop="materialSource">
          <el-select v-model="formData.materialSource" placeholder="请选择备件来源" clearable filterable style="width: 350px">
            <el-option v-for="item in materialSourceList" :key="item.value" :label="item.name" :value="item.value"
                       :disabled="item.value=='40'||item.value=='50'||item.value=='60'||item.value=='120'"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备件小类：" prop="subClass">
          <el-select v-model="formData.subClass" placeholder="请选择备件小类" clearable filterable style="width: 350px">
            <el-option v-for="item in subClassList" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="借出单号：">
          <el-input v-model.trim="formData.lendOrderCode" placeholder="请输入借出单号" style="width: 350px" clearable></el-input>
        </el-form-item>
        <el-form-item label="备件编码：">
          <el-input v-model.trim="formData.materialCode" placeholder="请输入备件编码" style="width: 350px" clearable></el-input>
        </el-form-item>
        <el-form-item label="检验比例(%)：" prop="testRate">
          <el-input-number v-model="formData.testRate" :min="0" :max="100"></el-input-number>
          <span style="font-size: 12px;color: orange;padding-left: 10px">注：0%表示免检。</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" :loading="loading" @click="addRule">确定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
export default {
  name: "RuleExemption",
  data() {
    return {
      queryInfo: {
        chargeLineCode: '',
        materialSource: '',
        subClass: '',
        materialCode: '',
        lendOrderCode: '',
        disabled: false,
        pageNo: 1
      },
      formDataRules: {
        chargeLineCode: [
          {required: true, message: '请选择运作产线', trigger: 'blur'}
        ],
        materialSource: [
          {required: true, message: '请选择备件来源', trigger: 'blur'}
        ],
        subClass: [
          {required: true, message: '请选择备件小类', trigger: 'blur'}
        ],
        testRate: [
          {required: true, message: '请输入检验比例', trigger: 'blur'}
        ]
      },
      formData: {
        chargeLineCode: '',
        materialSource: '',
        subClass: '',
        materialCode: '',
        lendOrderCode: '',
        testRate: 0
      },
      total: 0,
      pageSize: 0,
      ruleList: [],
      dialogVisible: false,
      loading: false,
      exemption: false
    }
  },
  computed: {
    chargeLineCodeList() {
      return this.$store.getters.getDictList('chargeLineCode') || [];
    },
    materialSourceList() {
      return this.$store.getters.getDictList('materialSource') || [];
    },
    subClassList() {
      return this.$store.getters.getDictList('subClass') || [];
    }
  },
  created() {
    this.search();
    this.queryIsEnableExemption();
  },
  methods: {
    async search() {
      this.loading = true;
      const {data: res} = await this.$axios.post('ruleExemption/queryRule', this.queryInfo);
      this.loading = false;
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.ruleList = res.data.records;
      this.total = res.data.total;
      this.pageSize = res.data.size;
    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.search()
    },
    disableRule(row) {
      this.$confirm('确定失效吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post('ruleExemption/disableRule', row.id).then(response => {
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message);
          }
          this.$message.success("失效成功");
          this.search();
        })
      })
    },
    openRule() {
      this.$confirm('确定启用吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post('ruleExemption/openRule').then(response => {
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message);
          }

          this.$message.success("启用成功");
          this.queryIsEnableExemption()
        })
      })
    },
    closeRule() {
      this.$confirm('确定停用吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post('ruleExemption/closeRule').then(response => {
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message);
          }

          this.$message.success("停用成功");
          this.queryIsEnableExemption()
        })
      })
    },
    queryIsEnableExemption() {
      this.$axios.get('ruleExemption/isEnableExemption').then(response => {
        if (response.data.code !== 0) {
          return this.$message.error(response.data.message);
        }
        let res = response.data;
        this.exemption = "true" == res.data;
      }, error => {
        return this.$message.error('查询电性能检验比例开关失败，' + error.message);
      })
    },
    async addRule() {

      if (!this.formData.chargeLineCode) {
        return this.$message.error('运作产线不能为空');
      }
      if (!this.formData.materialSource) {
        return this.$message.error('备件来源不能为空');
      }
      if (!this.formData.subClass) {
        return this.$message.error('备件小类不能为空');
      }

      this.loading = true
      const {data: res} = await this.$axios.post('ruleExemption/addRule', this.formData);
      this.loading = false
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.$message.success("添加成功");
      this.search();
      this.handleClose()
      this.formData.chargeLineCode = ''
      this.formData.materialSource = ''
      this.formData.subClass = ''
      this.formData.lendOrderCode = ''
      this.formData.materialCode = ''
      this.formData.testRate = 0
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
}
</script>

<style scoped>
.input {
  width: 200px;
  margin-right: 10px;
}
</style>